import React, {  useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import { IonRow, IonCol } from '@ionic/react';
import {
  TextField,
  FormHelperText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as Yup from 'yup';
import API from '../../api';
import webtitleImg from '../../assets/images/brand/lotusbrand.png';
import { IonSpinner } from '@ionic/react';
import { NavLink, useHistory } from 'react-router-dom';
import './SignUp.scss';
import HorizontalLabelPositionBelowStepper from './Steps';
import { BRAND_NAME } from '../../constants/Branding';
type StoreProps = {};

const SignUp: React.FC<StoreProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [userErrorMsg, setUserErrorMsg] = useState<string>('');
  const [otp, setOtp] = useState<number>(null);
  const [referralCode, setReferralCode] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [phone, setPhone] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [phoneFormat, setPhoneFormat] = useState<any>('');
  const [otpTimer, setOtpTimer]= useState<number>();

  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      fullname: '',
      username: '',
      email: '',
      password: '',
      // phoneNumber: '',
      address: '',
      city: '',
      country: '',
      otp: null,
      pinCode: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Required')
        .min(4, 'The User Name field must be at least 4 characters'),
      fullName: Yup.string(),
      email: Yup.string().email('Invalid email'),
      password: Yup.string().required('Required'),
      address: Yup.string(),
      city: Yup.string(),
      country: Yup.string(),
      pinCode: Yup.string(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let postBody = {
        fullName: values.fullname,
        username: values.username.toLowerCase(),
        password: values.password,
        email: values.email,
        phoneNumber: phone,
        address: values.address,
        city: values.city,
        pinCode: values.pinCode,
        otp: otp,
      };
      try {
        const response: any = await API.post(
          '/user/self-register-otp',
          postBody,
          {}
        );
        if (response.status === 200) {
          setLoading(false);
          toast.success(response.data.message);
          history.push('/');
        } else {
          toast.error(response.data.message);
        }
      } catch (err) {
        toast.error(err.toString());
        setLoading(false);
      }
    },
  });

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const handleOtpTimer = (time) => {
    if(time>=0) {
      setOtpTimer(time)
      setTimeout(()=>{
        handleOtpTimer(time-1);
      }, 1000);
    } else {
      setTimeout(()=>{
        setOtpTimer(undefined);
      }, 1000);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    const { value, name } = target;
    if (name === 'phone') {
      setPhone(value);
    } else if (name === 'otp') {
      setOtp(value);
    } else if (name === 'referralCode') {
      setReferralCode(value);
    }
  };

  const sendOTP = async () => {
    try {
      if (country.format.length === phoneFormat.length) {
        const response: any = await API.get(`/user/otp?mobileNub=${phone}`);
        if (response.status === 200) {
          if(response.data.message){
            handleOtpTimer(60);
            setActiveStep(1);  
          } else {
            throw new Error(response.data.message);
          }
        }
      }
    } catch (err) {
      console.log(err.toString());
      toast.error(err.toString());
      setLoading(false);
    }
  };

  const validateOtp = async () => {
    try {
      if (country.format.length === phoneFormat.length) {
        const response: any = await API.post(`/otp-validate?mobile=${phone}&otp=${otp}`, {});
        if (response && response.status === 200) {
          if(response.data.message) {
            setLoading(false);
            setActiveStep(2);  
          } else {
            throw new Error();
          }
        }
      }
    } catch (err) {
      toast.error('Invalid OTP entered, please try again.');
      setLoading(false);
    }
  };
  const handleNextStep = () => {
    if (phone === '') {
      toast.error('Please Enter Phone Number');
      return;
    }
    if (!otp || otp.toString().length !== 6) {
      toast.error('Please Enter Valid OTP');
      return;
    }
    setLoading(true);
    validateOtp();
  };
  const checkUserName = async (e) => {
    try {
      const { target } = e;
      const { value } = target;
      if (value.length > 3) {
        const response: any = await API.post(
          `/user/name-exits`,
          {},
          {
            params: {
              verifyName: value
            }
          }
        );
        if (response.status === 200) {
          if (response.data.message) {
            setUserErrorMsg('User name is already exist');
          } else {
            setUserErrorMsg('');
          }
        }
      }
    } catch (err) {
      toast.error('Something went wrong, Please try again');
    }
  };
  return (
    <div className="sign-up-ctn">
      <IonRow>
        <IonCol sizeLg="12" sizeXs="12" className="card-col">
          <div className="title-row">
            <img src={webtitleImg} alt="title" className="logo" />
          </div>
          <div className="card-bg">
            <div className="card-section">
              {activeStep === 0 ? (
                <>
                  <div className="card">
                    <div className="page-title">Create account</div>
                    <IonRow>
                      {' '}
                      <HorizontalLabelPositionBelowStepper
                        activeStep={activeStep}
                      />{' '}
                    </IonRow>
                    <IonRow>
                      <IonCol sizeXs="12" sizeLg="12" className="input-col">
                        <div className="input-row">
                          <div className="label">Phone Number</div>
                          <PhoneInput
                            country={'in'}
                            placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(value, country, e, formattedValue) => {
                              setPhone(value);
                              setCountry(country);
                              setPhoneFormat(formattedValue);
                            }}
                          />
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeLg="12" sizeXs="12" className="input-col">
                        <div className="input-row">
                          <div className="label">Referral Code</div>
                          <TextField
                            className="number-input"
                            type="text"
                            name="referralCode"
                            value={referralCode}
                            variant="outlined"
                            placeholder="Enter Referral Code "
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeLg="12" sizeXs="12" className="input-col">
                        <FormGroup className="mt-0">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => setAgreeTerms(!agreeTerms)}
                              />
                            }
                            label={`
                          I agree to the all terms & Conditions of ${BRAND_NAME}.`}
                          />
                        </FormGroup>
                      </IonCol>
                    </IonRow>

                    <div className="btn-section">
                      <Button
                        className="btn"
                        color="primary"
                        type="submit"
                        variant="contained"
                        endIcon={
                          loading ? <IonSpinner name="lines-small" /> : ''
                        }
                        disabled={!agreeTerms}
                        onClick={() => sendOTP()}
                      >
                        Send OTP
                      </Button>
                    </div>

                    <IonRow className="msg-row">
                      <div className="msg">
                        Have an account? <NavLink to="/">Sign In</NavLink>
                      </div>
                    </IonRow>
                  </div>
                </>
              ) : null}
              {activeStep === 1 ? (
                <>
                  <div className="card">
                    <div className="page-title">Create account</div>
                    <IonRow>
                      {' '}
                      <HorizontalLabelPositionBelowStepper
                        activeStep={activeStep}
                      />{' '}
                    </IonRow>
                    <IonRow>
                      <IonCol sizeLg="12" sizeXs="12" className="input-col">
                        <div className="input-row flex-column">
                          <div className="label">OTP *</div>
                          <TextField
                            className="number-input"
                            // type="number"
                            name="otp"
                            variant="outlined"
                            placeholder="Enter OTP"
                            onKeyPress={(evt) => {
                              if (
                                (evt.which != 8 &&
                                  evt.which != 0 &&
                                  evt.which < 48) ||
                                evt.which > 57
                              ) {
                                evt.preventDefault();
                              }
                            }}
                            onChange={(e) => handleChange(e)}
                          />
                          <div
                            className="label link"
                            onClick={() => {
                              if (!otpTimer) {
                                sendOTP();
                              }
                            }}
                          >
                            {otpTimer !== undefined &&
                            otpTimer !== null &&
                            otpTimer >= 0
                              ? `Resend in ${otpTimer} sec`
                              : 'Send OTP'}
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                    <div className="btn-section">
                      <Button
                        className="btn"
                        color="primary"
                        type="submit"
                        variant="contained"
                        endIcon={
                          loading ? <IonSpinner name="lines-small" /> : ''
                        }
                        disabled={!agreeTerms}
                        onClick={() => handleNextStep()}
                      >
                        Verify OTP
                      </Button>
                    </div>

                    <IonRow className="msg-row">
                      <div
                        className="msg"
                        onClick={() => history.push('/home')}
                      >
                        Have an account? <NavLink to="/">Sign In</NavLink>
                      </div>
                    </IonRow>
                  </div>
                </>
              ) : null}
              {activeStep === 2 ? (
                <>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="card"
                    autoComplete="off"
                  >
                    <div className="page-title">Create account</div>
                    <IonRow>
                      {' '}
                      <HorizontalLabelPositionBelowStepper
                        activeStep={activeStep}
                      />{' '}
                    </IonRow>
                    <IonRow className="input-row">
                      <div className="form-control">
                        <div className="label">Username *</div>
                        <TextField
                          className="login-input-field user-name"
                          type="text"
                          placeholder="Enter Username"
                          variant="outlined"
                          name="username"
                          onKeyUp={(e) => checkUserName(e)}
                          error={
                            (formik.touched.username &&
                              formik.errors.username) ||
                            userErrorMsg !== ''
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.username && formik.errors.username
                              ? formik.errors.username
                              : userErrorMsg !== ''
                              ? userErrorMsg
                              : null
                          }
                          {...formik.getFieldProps('username')}
                        />
                      </div>

                      <div className="form-control">
                        <div className="label">Full Name *</div>
                        <TextField
                          className="login-input-field user-name"
                          type="text"
                          name="fullname"
                          variant="outlined"
                          placeholder="Enter Fullname"
                          error={
                            formik.touched.fullname && formik.errors.fullname
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.fullname && formik.errors.fullname
                              ? formik.errors.fullname
                              : null
                          }
                          {...formik.getFieldProps('fullname')}
                        />
                      </div>
                    </IonRow>

                    <IonRow className="input-row">
                      <div className="form-control">
                        <div className="label">Password *</div>
                        <FormControl
                          className="login-input-field pwd-field"
                          variant="outlined"
                          error={
                            formik.touched.password && formik.errors.password
                              ? true
                              : false
                          }
                        >
                          <OutlinedInput
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter Password"
                            {...formik.getFieldProps('password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={showPasswordClickHandler}
                                  onMouseDown={showPasswordClickHandler}
                                >
                                  {showPassword ? (
                                    <Visibility className="no-margin" />
                                  ) : (
                                    <VisibilityOff className="no-margin" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <FormHelperText error id="my-helper-text">
                              {formik.errors.password}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </div>

                      <div className="form-control">
                        <div className="label">Email</div>
                        <TextField
                          className="login-input-field user-name"
                          type="text"
                          name="email"
                          variant="outlined"
                          placeholder="Enter Email"
                          error={
                            formik.touched.email && formik.errors.email
                              ? true
                              : false
                          }
                          {...formik.getFieldProps('email')}
                          helperText={
                            formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : null
                          }
                        />
                      </div>
                    </IonRow>
                    <IonRow className="input-row">
                      <div className="form-control">
                        <div className="label">Address</div>
                        <TextField
                          className="login-input-field user-name"
                          type="text"
                          name="Enter Address"
                          variant="outlined"
                          placeholder="Address"
                          error={
                            formik.touched.address && formik.errors.address
                              ? true
                              : false
                          }
                          {...formik.getFieldProps('address')}
                          helperText={
                            formik.touched.address && formik.errors.address
                              ? formik.errors.address
                              : null
                          }
                        />
                      </div>{' '}
                      <div className="form-control">
                        <div className="label">City</div>
                        <TextField
                          className="login-input-field user-name"
                          type="text"
                          name="city"
                          variant="outlined"
                          placeholder="Enter City"
                          error={
                            formik.touched.city && formik.errors.city
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.city && formik.errors.city
                              ? formik.errors.city
                              : null
                          }
                          {...formik.getFieldProps('city')}
                        />
                      </div>
                    </IonRow>

                    <IonRow className="input-row">
                      <div className="form-control">
                        <div className="label">Pincode</div>
                        <TextField
                          type="text"
                          className="login-input-field user-name"
                          name="pinCode"
                          variant="outlined"
                          placeholder="Enter PinCode"
                          error={
                            formik.touched.pinCode && formik.errors.pinCode
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.pinCode && formik.errors.pinCode
                              ? formik.errors.pinCode
                              : null
                          }
                          {...formik.getFieldProps('pinCode')}
                        />
                      </div>

                      <div className="form-control">
                        <div className="label">Country</div>
                        <TextField
                          type="text"
                          className="login-input-field user-name"
                          name="country"
                          variant="outlined"
                          placeholder="Enter Country"
                          error={
                            formik.touched.country && formik.errors.country
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.country && formik.errors.country
                              ? formik.errors.country
                              : null
                          }
                          {...formik.getFieldProps('country')}
                        />
                      </div>
                    </IonRow>

                    <IonRow></IonRow>
                    <IonRow className="button-row">
                      {/* <Button
                        className="back-form-btn"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          setOtp(null);
                          setActiveStep(0);
                        }}
                      >
                        Back
                      </Button> */}
                      <Button
                        className="signup-btn"
                        color="primary"
                        endIcon={
                          loading ? <IonSpinner name="lines-small" /> : ''
                        }
                        type="submit"
                        variant="contained"
                        disabled={!(formik.isValid && userErrorMsg === '')}
                      >
                        SignUp
                      </Button>
                    </IonRow>
                  </form>
                </>
              ) : null}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(SignUp);

import { fetchIndCasinoGame } from '../../api-services/backend/casino.service';
import { CasinoGameDTO } from '../../models/IndianCasinoState';
import {
  SET_CASINO_GAME,
  UPDATE_CASINO_GAMES,
  UPDATE_GAME_DATA,
  UPDATE_GAME_RESULTS,
} from './CasinoActionTypes';

export const updateCasinoGames = (casinoGames: CasinoGameDTO[]) => {
  return {
    type: UPDATE_CASINO_GAMES,
    payload: casinoGames,
  };
};

export const setCasinoGame = (game: CasinoGameDTO) => {
  return {
    type: SET_CASINO_GAME,
    payload: game,
  };
};

export const fetchGameData = (gameCode: string) => {
  return async (dispatch: Function) => {
    try {
      if (gameCode !== '') {
        const result = await fetchIndCasinoGame(gameCode);
        if (result.data && result.data.mId) {
          dispatch(updateGameData(result.data));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateGameData = (gameData) => {
  return {
    type: UPDATE_GAME_DATA,
    payload: gameData,
  };
};

export const updateGameResults = (gameResults) => {
  return {
    type: UPDATE_GAME_RESULTS,
    payload: gameResults,
  };
};

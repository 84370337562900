import React, { useState } from 'react';
import { IonButton } from '@ionic/react';
import { Dialog, Button } from '@material-ui/core';
import { VpnLock } from '@material-ui/icons';
import './RestrictionsInfo.scss';

const TerritoriesRestriction: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = window.innerWidth > 720 ? false : true;
  return (
    <div className="restrictions-info-ctn">
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        className="modal-footer-web"
      >
        <div className="modal-div-body">
          <div className="restricted-title">
            <div>
              {' '}
              <VpnLock className="icon"></VpnLock>
            </div>
            <div className="name"> Restricted Territories</div>
          </div>
          <div className="modal-body">
            <p>
              Access to this site is restricted in certain geographical
              territories. Customers residing in the listed territories are
              prohibited from accessing the site and its services.
            </p>
            <p>The restricted territories are:</p>
            <p>
              Afghanistan, Algeria, Australia, Bosnia and Herzegovina, Bulgaria,
              Canada, China (People's Republic of), Cuba, Cyprus, Denmark,
              Ethiopia, France (and French territories), Germany, Gibraltar,
              Iran (Islamic Republic of), Iraq, Ireland, Italy, Korea
              (Democratic People's Republic of), Lao (People's Democratic
              Republic of), Malta, Myanmar, Netherlands, New Zealand, Poland,
              Portugal, Puerto Rico, Qatar, Romania, Singapore, Slovakia, South
              Africa, Spain, Sudan, Syrian Arab Republic, Turkey, Uganda, United
              Kingdom, United States (and U.S. Territories), Yemen.
            </p>
          </div>

          <div className="modal-continue">
            <Button onClick={() => setShowModal(false)} className="btn">
              ok
            </Button>
          </div>
        </div>
      </Dialog>
      <IonButton
        className="restrictions-info-btn"
        fill="clear"
        onClick={() => setShowModal(isMobile ? false : true)}
      >
        <VpnLock />
        <span className="btn-text">Restricted territories</span>
      </IonButton>
    </div>
  );
};

export default TerritoriesRestriction;

import {
  SET_MULTIMARKET_EVENT_DATA,
  UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS,
  UPDATE_MULTIMARKET_SECONDARY_MARKETS,
  UPDATE_MULTIMARKET_BOOKMAKER_MARKETS,
  MULTI_SUSPENDED_MARKETS,
  TRIGGER_MULTI_FETCH_MARKETS,
  TRIGGER_MULTI_FETCH_ORDERS,
  MULTI_DISABLED_MARKETS,
} from './multimarketActionTypes';
import { UPDATE_TOPIC_URLS } from '../exchangeSports/exchangeSportsActionTypes';
import API from '../../api';
import { EventDTO } from '../../models/common/EventDTO';
import { EXCH_SPORTS_MAP } from '../../constants/ExchangeEventTypes';
import { PROVIDER_ID } from '../../constants/Branding';
import SVLS_API from '../../api-services/svls-api';
import { isAccountPath } from '../common/commonActions';
import { getAccountPathFromToken } from '../auth/authActions';

export const addToMultiMarket = (
  competitionId,
  eventId,
  marketId,
  providerId,
  sportId
) => {
  const username = sessionStorage.getItem('username') ?? '';
  if (marketId && username) {
    let data = [];
    const localData = localStorage.getItem(`multiMarket_${username}`) ?? '';
    if (localData) data = JSON.parse(atob(localData));
    const marketInclue = data?.filter((itm) => itm.marketId === marketId);
    if (marketInclue?.length === 0) {
      data.push({
        competitionId,
        eventId,
        marketId,
        providerId,
        sportId,
      });
      localStorage.setItem(
        `multiMarket_${username}`,
        btoa(JSON.stringify(data))
      );
    }
  }
};
export const removeToMultiMarket = (eventId, marketId) => {
  const username = sessionStorage.getItem('username') ?? '';
  if (username && marketId) {
    let data = [];
    const localData = localStorage.getItem(`multiMarket_${username}`) ?? '';
    if (localData) data = JSON.parse(atob(localData));
    const index = data?.findIndex(
      (itm) => itm.eventId === eventId && itm.marketId === marketId
    );
    index > -1 && data.splice(index, 1);
    index > -1 &&
      localStorage.setItem(
        `multiMarket_${username}`,
        btoa(JSON.stringify(data))
      );
  }
};

export const checkIncludeMultiMarket = (marketData, marketId, eventId) => {
  let marketInclue = marketData.filter((itm) => itm.marketId === marketId);
  return marketInclue.length ? true : false;
};

const setMultiMarketEventData = (payload) => {
  return {
    type: SET_MULTIMARKET_EVENT_DATA,
    payload: payload,
  };
};

export const updateMultiSecondaryMatchOdds = (payload) => {
  return {
    type: UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS,
    payload: payload,
  };
};

const updateMultiSecondaryMarkets = (payload) => {
  return {
    type: UPDATE_MULTIMARKET_SECONDARY_MARKETS,
    payload: payload,
  };
};

// export const updateMultiFancyMarkets = (payload) => {
//   return {
//     type: UPDATE_MULTIMARKET_FANCY_MARKETS,
//     payload: payload,
//   };
// };

export const updateMultiOddsfromWS = (payload) => {
  return {
    type: SET_MULTIMARKET_EVENT_DATA,
    payload: payload,
  };
};

export const updateMultiSuspendedMarkets = (payload) => {
  return {
    type: MULTI_SUSPENDED_MARKETS,
    payload: payload,
  };
};

export const updateMultiDisabledMarkets = (payload) => {
  return {
    type: MULTI_DISABLED_MARKETS,
    payload: payload,
  };
};

export const updateMultiBookMakerMarkets = (payload) => {
  return {
    type: UPDATE_MULTIMARKET_BOOKMAKER_MARKETS,
    payload: payload,
  };
};

export const triggerMultiFetchMarkets = (payload) => {
  return {
    type: TRIGGER_MULTI_FETCH_MARKETS,
    payload: {
      accountPath: isAccountPath(payload.limitKey)
        ? getAccountPathFromToken()
        : null,
      data: payload,
    },
  };
};

export const triggerMultiFetchOrders = (eventId) => {
  return {
    type: TRIGGER_MULTI_FETCH_ORDERS,
    payload: eventId,
  };
};

const updateTopicUrlsInStore = (dispatch: Function, eventData: any) => {
  // Dispatch topic urls
  const topicUrlPayload = {
    matchOddsBaseUrl: eventData?.markets?.matchOddsBaseUrl,
    matchOddsTopic: eventData?.markets?.matchOddsTopic,
    bookMakerBaseUrl: eventData?.markets?.bookMakerBaseUrl,
    bookMakerTopic: eventData?.markets?.bookMakerTopic,
    fancyBaseUrl: eventData?.markets?.fancyBaseUrl,
    fancyTopic: eventData?.markets?.fancyTopic,
    premiumBaseUrl: eventData?.markets?.premiumBaseUrl,
    premiumTopic: eventData?.markets?.premiumTopic,
  };
  dispatch(updateTopicUrls(topicUrlPayload));
};

export const updateTopicUrls = (payload) => {
  return {
    type: UPDATE_TOPIC_URLS,
    payload: payload,
  };
};

export const fetchMultiMarketEventData = () => {
  return async (dispatch: Function) => {
    try {
      let data = [];
      const username = sessionStorage.getItem('username') ?? '';
      const multiMarket = localStorage.getItem(`multiMarket_${username}`);
      if (multiMarket) data = JSON.parse(atob(multiMarket));
      if (data?.length) {
        const response = await SVLS_API.post(
          `/catalog/v2/sports-feed/sports/markets`,
          {
            eventDetails: data,
            providerId: PROVIDER_ID,
            marketsCriteria: 'ALL',
          },
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
        if (response?.data?.length > 0) {
          updateTopicUrlsInStore(dispatch, response.data[0]);
          for (let i = 0; i < response?.data?.length; i++) {
            const eventData = response.data[i];
            if (eventData.eventId) {
              const eData: EventDTO = {
                status: eventData.status,
                enabled: eventData.enabled,
                openDate: eventData.openDate,
                sportId: eventData.sportId,
                competitionId: eventData.competitionId,
                competitionName: eventData.competitionName
                  ? eventData.competitionName
                  : 'Other',
                eventId: eventData.eventId,
                eventName: eventData.eventName,
                marketId: eventData.marketId,
                providerName: eventData.providerName,
              };

              const payload = {
                eventData: eData,
                //eventData: response.data,
                sportId: eData.sportId,
                competitionId: eData.competitionId,
                eventId: eventData.eventId,
                matchOddsData:
                  eventData.markets && eventData.markets.matchOdds
                    ? eventData.markets.matchOdds.find(
                        (mo) =>
                          mo.marketName === 'Match Odds' ||
                          mo.marketName.toLowerCase() === 'moneyline'
                      )
                    : null,
                onRefresh: true,
              };

              dispatch(setMultiMarketEventData(payload));
              // if (eData.sportId === '1') {
              //|| eData.sportId === '4'
              for (let mo of eventData.markets.matchOdds) {
                if (eData.sportId === '4') {
                  if (
                    mo.marketName !== 'Match Odds' &&
                    mo.marketName.toLowerCase() !== 'moneyline' &&
                    mo.marketId !== '1.196548297' &&
                    mo.marketId !== '1.196548301'
                  ) {
                    const secMOPayload = {
                      sportId: eventData.sportId,
                      competitionId: eventData.competitionId,
                      eventId: eventData.eventId,
                      marketId: mo.marketId,
                      matchOddsData: mo,
                    };

                    dispatch(updateMultiSecondaryMatchOdds(secMOPayload));
                  }
                } else {
                  if (
                    mo.marketName !== 'Match Odds' &&
                    mo.marketName.toLowerCase() !== 'moneyline'
                  ) {
                    const secMOPayload = {
                      sportId: eventData.sportId,
                      competitionId: eventData.competitionId,
                      eventId: eventData.eventId,
                      marketId: mo.marketId,
                      matchOddsData: mo,
                    };

                    dispatch(updateMultiSecondaryMatchOdds(secMOPayload));
                  }
                }
              }
              // }

              if (eData.sportId === '4') {
                const secMarketsPayload = {
                  sportId: eventData.sportId,
                  competitionId: eventData.competitionId,
                  eventId: eventData.eventId,
                  bookmakerOddsData:
                    eventData.markets && eventData.markets.bookmakers
                      ? eventData.markets.bookmakers
                      : null,
                  enableBookmaker: eventData.markets
                    ? eventData.markets.enableBookmaker
                    : false,
                  sessionOddsData:
                    eventData.markets && eventData.markets.fancyMarkets
                      ? eventData.markets.fancyMarkets
                      : null,
                  enableFancy: eventData.markets
                    ? eventData.markets.enableFancy
                    : false,
                };
                dispatch(updateMultiSecondaryMarkets(secMarketsPayload));
              }
            }
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
};

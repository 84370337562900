import React, { Suspense, lazy, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { ToastContainer } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LoginPage from './components/LoginForm/LoginForm';
import SignUp from './pages/SignUp/SignUp';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import { RootState } from './models/RootState';

import AcceptTerms from './pages/AcceptTerms/AcceptTerms';
import { logout } from './store';
import ResetPassword from './pages/ResetPasswordPage/ResetPasswordPage';
import AccessRedirect from './pages/AccessRedirect/Accessredirect';
import { setTimeout } from 'timers';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import NotificationPage from './components/NotificationPage/NotificationPage';

const MainPage = lazy(() => import('./router/UserRouter'));

type StateProps = {
  logout: () => void;
  mailVerified: boolean;
  loggedIn: boolean;
};

const App: React.FC<StateProps> = (props) => {
  const consoleOpen = false; //useConsoleOpen();
  // const consoleOpen = false;

  const { logout, loggedIn } = props;

  const handleOnIdle = () => {
    console.log('user is idle');
    if (loggedIn) {
      logout();
    }
  };

  const handleClose = () => {
    console.log('user open dev tools');
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      {!consoleOpen ? (
        <Suspense fallback={<LoadingPage />}>
          <BrowserRouter>
            <Switch>
              <Route path="/access-redirect" component={AccessRedirect} />
              <Route path="/terms-and-conditions" component={AcceptTerms} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/allAnnouncement" component={NotificationPage} />
              <Route path="/sign-up" component={SignUp} />

              {loggedIn ? (
                <Route path="/" component={MainPage} />
              ) : (
                <Route path="/" component={LoginPage} />
              )}
            </Switch>
          </BrowserRouter>
        </Suspense>
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Dialog
        open={consoleOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        className="dev-tools-msg-modal"
      >
        <DialogContent className="modal-content-ctn">
          <div className="dev-tools-warning-msg">
            {' '}
            Due to some inactivity or security reasons stop your website, please
            close the developer tool. Thank you for your support
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

// export const useConsoleOpen = () => {
//   // const [consoleOpen, setConsoleOpen] = useState(false);

//   useEffect(() => {
//     let checkStatus;

//     var element = new Image();
//     Object.defineProperty(element, 'id', {
//       get: function () {
//         checkStatus = true;
//         throw new Error('Dev tools checker');
//       },
//     });
//     requestAnimationFrame(function check() {
//       setTimeout(() => {
//         checkStatus = false;
//         // Don't delete this console statements
//         if (process.env.REACT_APP_NODE_ENV !== 'development') {
//           console.dir(element);
//           console.clear();
//         }
//         // setConsoleOpen(checkStatus);
//         requestAnimationFrame(check);
//       }, 1000);
//     });
//   });

//   return false;
// };

const mapStateToProps = (state: RootState) => {
  return {
    mailVerified: state.auth.mailVerified,
    prefersDark: state.common.prefersDark,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store';
import { IonIcon, IonCol, IonRow } from '@ionic/react';
import NotificationIcon from '../../assets/images/common/Notifications.svg';
import './NotificationPage.scss';
import { Notification } from '../../models/Notification';
import { RootState } from '../../models/RootState';
import API from '../../api';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
type StoreProps = {
  logout: Function;
  loggedIn: boolean;
};

const NotificationPage: React.FC<StoreProps> = (props) => {
  const { logout, loggedIn } = props;
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const location = useLocation();
  useEffect(() => {
    if (loggedIn) {
      fetchNotifications();
    }
  }, [loggedIn]);
  const fetchNotifications = async () => {
    const response = await API.get('/notifications', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
      params: {
        isActive: true,
      },
    });
    setNotifications(response.data.filter((n) => n.active));
  };

  const getNotificationMessages = (notificationsList: Notification[]) => {
    let notfMsg = ' ';
    for (let i = 0; i < notificationsList.length; i++) {
      if (notificationsList[i].active) {
        notfMsg += ' ' + notificationsList[i].message;
        notfMsg += notificationsList[i + 1] ? '  ' + '  '.repeat(8) : '';
      }
    }

    return notfMsg;
  };

  return (
    <>
      <div className="notificatio-ctn">
          <div className="title">
          Announcement
          </div>
        {notifications && notifications.length > 0 ? (
          notifications.map((itm, index) => {
            return (
              <>
                <div className="card11">
                  {location?.pathname === '/allAnnouncement' ? null : (
                    <div className="icon-ctn">
                      {' '}
                      <IonIcon src={NotificationIcon} className="icon" />
                    </div>
                  )}
                  <div className="text1">
                    {moment(itm.startTime).format('DD-MM-YY ')} - {itm?.message}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div className="card11">
            <div className="icon-ctn">
              {' '}
              <IonIcon src={NotificationIcon} className="icon" />
            </div>

            <div className="text1">No Notification</div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);

import React, { useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { Button, Dialog } from '@material-ui/core';
import { add } from 'ionicons/icons';
import './RestrictionsInfo.scss';

const AgeRestriction: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = window.innerWidth > 720 ? false : true;
  return (
    <div className="restrictions-info-ctn">
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        className="modal-footer-web"
      >
        <div className="modal-div-body">
          <div className="modal-title">Protection of minors</div>
          <div className="modal-body">
            <p> It is illegal for anybody under the age of 18 to gamble.</p>

            <p>
              {' '}
              Our site has strict policies and verification measures to prevent
              access to minors. .
            </p>

            <p>
              {' '}
              We encourage parents consider the use of internet use protection
              tools. <br></br>
              You may find the following links useful.
            </p>
            <ul>
              <li>
                <a href="https://www.cyberpatrol.com/" target="_blank">
                  Cyberpatrol
                </a>
              </li>
              <li>
                <a href="https://www.cybersitter.com/" target="_blank">
                  Cybersitter
                </a>
              </li>
            </ul>
          </div>
          <div className="modal-continue">
            <Button
              onClick={() => {
                setShowModal(false);
              }}
              className="btn"
            >
              ok
            </Button>
          </div>
        </div>
      </Dialog>
      <IonButton
        className="restrictions-info-btn"
        fill="clear"
        onClick={() => setShowModal(isMobile ? false : true)}
      >
        <span className="age-text">
          18
          <IonIcon icon={add} />
        </span>
        <span className="btn-text mob-show">
          Underage gambling is an offence
        </span>
      </IonButton>
    </div>
  );
};

export default AgeRestriction;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonRow, IonSpinner, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import AgeRestriction from '../Footer/RestrictionsInfo/AgeRestriction';
import TerritoriesRestriction from '../Footer/RestrictionsInfo/TerritoriesRestriction';
import { VpnLock } from '@material-ui/icons';
import './LoginForm.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import API from '../../api';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
import gamcare from '../../assets/images/common/icons/gameCare.svg';
import API_V2 from '../../api-services/svls-api';
import { BRAND_DOMAIN } from '../../constants/Branding';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  modalCloseHandler: Function;
  redirectUrl?: string;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    modalCloseHandler,
    redirectUrl,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [showBottomSheet, setShowBottomSheet] = useState<string>('');
  const [demoLoginEnabled, setDemoLoginEnabled] = useState<boolean>(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);
  const [signUpLink, setSignUpLink] = useState<string>(null);

  let history = useHistory();
  const { search } = useLocation();
  const authToken = new URLSearchParams(search)?.get('authToken');
  useEffect(() => {
    if (authToken) {
      getSapToken(authToken);
      const username = authToken?.split('.')?.[1];
      let uname = JSON.parse(window?.atob(username)).sub;
      sessionStorage.setItem('username', uname);
      sessionStorage.setItem('jwt_token', authToken);
      loginSuccess({ jwtToken: authToken });
      history.push('/home');
      fetchBalance();
    }
  }, [authToken]);

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      requestStart();
      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password.trim(),
        };

        const response = await API_V2.post('/account/v2/login', loginRequest, {
          responseType: 'text',
        });
        requestEnd();

        sessionStorage.setItem('username', values.username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        localStorage.removeItem(
          `multiMarket_${values?.username?.toLowerCase()}`
        );

        loginSuccess({ jwtToken: response.data });
        getSapToken(response.data);
        let claim = response.data.split('.')[1];
        let permission = JSON.parse(window.atob(claim)).perm;
        let status = JSON.parse(window.atob(claim)).sts;

        if (status === 2) {
          history.replace('/terms-and-conditions');
        } else if (status === 4) {
          history.replace('/reset-password');
        } else if ((permission & 2) !== 0) {
          history.replace('/platform_admin/house');
        }

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          history.push('/home');
        }
      } catch (err) {
        loginFailed(err?.response?.data?.message);
      }
    },
  });


  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await API_V2.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        requestEnd();

        let claim = response.data.split('.')[1];
        sessionStorage.setItem('username', JSON.parse(window.atob(response.data.split('.')[1])).sub.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        localStorage.removeItem(`multiMarket_${JSON.parse(window.atob(response.data.split('.')[1])).sub.toLowerCase()}`);

        loginSuccess({ jwtToken: response.data });

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          history.push('/home');
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    isSignUpEnabled();
  }, []);

  const isSignUpEnabled = () => {
    if (window.location.hostname.includes('lotus6ball.com')) {
      setSignUpLink('https://wa.link/6ball');
      return;
    }
    setSignUpLink(null);
  };

  const getSapToken = async (token: string) => {
    try {
      const response = await API_V2.get(`/account/v2/accounts/${JSON.parse(window.atob(token.split('.')[1])).aid}/sap-token`,
      {
        headers: {
          Authorization: token,
        },
      });
      if (response.status == 200) {
        sessionStorage.setItem('sap_token', response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    isDemoLoginEnabled()
  }, []);

  const isDemoLoginEnabled = async () => {
    try {
      const response = await API_V2.get(`/account/v2/books/${BRAND_DOMAIN}`);
      if (response.status === 200) {
        setDemoLoginEnabled(response.data.demo_user_enabled);
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (loggedIn) {
      modalCloseHandler();
    }
  }, [loggedIn, modalCloseHandler]);

  return (
    <>
      <div className="login-root">
        <div className="center-content">
          <div className="login-form">
            <IonRow className="title-logo">
              <div className="logo" />
            </IonRow>
            <form
              onSubmit={formik.handleSubmit}
              className="mob-login-ctn"
              autoComplete="off"
            >
              <TextField
                className="input-field"
                type="text"
                name="username"
                variant="outlined"
                placeholder="Username"
                {...formik.getFieldProps('username')}
              />

              <FormControl
                className="input-field"
                variant="outlined"
                placeholder="Password"
              >
                <OutlinedInput
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  {...formik.getFieldProps('password')}
                />
              </FormControl>

              {useAuthenticator ? (
                <span className="code-input">
                  <IonLabel className="input-label">Code</IonLabel>
                  <TextField
                    className="login-input-field user-name"
                    type="text"
                    name="username"
                    variant="outlined"
                    {...formik.getFieldProps('code')}
                  />
                </span>
              ) : null}

              {errorMsg !== '' ? (
                <span className="login-err-msg">{errorMsg}</span>
              ) : null}

              <div className='login-and-demo-login'>
                <Button
                  className={demoLoginEnabled ? "login-btn" : "login-btn-without-demologin"}
                  color="primary"
                  endIcon={loading && loadLogin ? <IonSpinner name="lines-small" /> : ''}
                  type="submit"
                  variant="contained"
                >
                  Login
                </Button>

                {demoLoginEnabled ?
                  <Button
                    className="login-btn"
                    color="primary"
                    endIcon={demoLoading ? <IonSpinner name="lines-small" /> : ''}
                    onClick={handleDemoLogin}
                    variant="contained"
                  >
                    Demo Login
                  </Button>
                  : null}
              </div>
              {BRAND_DOMAIN.toLocaleLowerCase() === 'sky11.com' ? (
                <IonRow className="msg-row">
                  <a href="https://linktr.ee/SKY_EXCH" target="_blank">
                    <div className="msg">
                      Don't have an account? Create account
                    </div>
                  </a>
                </IonRow>
              ) : null}

              {signUpLink ? (
                <div className="sign-up-link">
                  <a href={signUpLink} target="_blank" className="ml-5">
                    <Button className="sign-up-btn">Sign Up</Button>
                  </a>
                </div>
              ) : null}
              <Modal
                open={showForgotPwdModal}
                closeHandler={() => setShowForgotPwdModal(false)}
                title="Forgot Password"
                size="xs"
              >
                <ForgotPwdForm
                  closeHandler={() => setShowForgotPwdModal(false)}
                />
              </Modal>
            </form>
          </div>
        </div>

        <IonRow className="login-footer web-view">
          <AgeRestriction />
          <TerritoriesRestriction />
        </IonRow>
        <div className="login-footer-container mob-view">
          {showBottomSheet === '' ? (
            <div className="login-footer mob-view">
              <a href="https://www.gamcare.org.uk/" target="_blank">
                <img src={gamcare} className="gamecareicon mob-view" />
              </a>
              <div onClick={() => setShowBottomSheet('restriction')}>
                <TerritoriesRestriction />
              </div>
              <div onClick={() => setShowBottomSheet('age')}>
                <AgeRestriction />
              </div>
            </div>
          ) : (
            <div className="login-footer-unfolded mob-view">
              <div className="header" onClick={() => setShowBottomSheet('')}>
                <div className="restricted-territories">
                  {showBottomSheet === 'age' ? (
                    <>
                      <span className="age-text">
                        18
                        <IonIcon icon={add} />
                      </span>
                      <span className="ico-txt">
                        Underage gambling is an offence
                      </span>
                    </>
                  ) : (
                    <>
                      <VpnLock />
                      <span className="ico-txt">restricted territories</span>
                    </>
                  )}
                </div>
              </div>
              <div className="details" onClick={() => setShowBottomSheet('')}>
                {showBottomSheet === 'age' ? (
                  <>
                    <div>
                      <div className="title">Protections of minors</div>
                      <p>
                        It is illegal for anybody under the age of 18 to gamble.
                      </p>
                      <p>
                        Our site has strict policies and verification measures
                        to prevent access to minors.
                      </p>
                      <p>
                        We encourage parents consider the use of internet use
                        protection tools. You may find the following links
                        useful.
                      </p>
                    </div>
                    <ul className="links">
                      <li>
                        <a href="https://www.cyberpatrol.com/" target="_blank">
                          Cyberpatrol
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cybersitter.com/" target="_blank">
                          Cybersitter
                        </a>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <p>
                      Access to this site is restricted in certain geographical
                      territories. Customers residing in the listed territories
                      are prohibited from accessing the site and its services.
                    </p>
                    <p>The restricted territories are:</p>
                    <p>
                      Afghanistan, Algeria, Australia, Bangladesh, Bosnia and
                      Herzegovina, Bulgaria, Canada, China (People's Republic
                      of), Cuba, Cyprus, Denmark, Ethiopia, France (and French
                      territories), Germany, Gibraltar, Iran (Islamic Republic
                      of), Iraq, Ireland, Italy, Korea (Democratic People's
                      Republic of), Lao (People's Democratic Republic of),
                      Macau, Malta, Myanmar, Netherlands, New Zealand, Pakistan,
                      Poland, Portugal, Puerto Rico, Qatar, Romania, Singapore,
                      Slovakia, South Africa, Spain, Sudan, Syrian Arab
                      Republic, Taiwan, Turkey, Uganda, United Kingdom, United
                      States (and U.S. Territories), Uruguay, Yemen.
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

import { ExBetslip } from '../../models/ExBetslip';
import {
  ADD_EXCH_BET,
  EXCH_BET_ODDS_CHANGE,
  REMOVE_EXCH_BET,
  SET_EXCH_BET_STAKE,
  CLEAR_EXCHANGE_BETS,
  FETCH_OPEN_BETS_SUCCESS,
  CLEAR_SUCCESS_BETS,
  SET_BUTTON_VARIABLES,
  SET_BETTING_INPROGRESS,
  ENABLE_ONE_CLICK_BETS,
  ADD_ONE_CLICK_AMOUNT,
} from './exchBetslipActionTypes';
import { BUTTON_VARIABLES } from '../../constants/ButtonVariables';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExBetslip = {
  bets: [],
  openBets: [],
  totalOrders: 0,
  buttonVariables: BUTTON_VARIABLES,
  bettingInprogress: false,
  isOneClickEnable: false,
  oneClickAmount: 0,
};

const ExchBetslipReducer = (
  state = initialState,
  action: Action
): ExBetslip => {
  switch (action.type) {
    case ADD_EXCH_BET: {
      const bettingInprogress = state.bettingInprogress;
      if (!bettingInprogress) {
        const bets = [...state.bets];
        bets.push(action.payload);
        return {
          ...state,
          bets,
        };
      }
      return {
        ...state,
      };
    }
    case EXCH_BET_ODDS_CHANGE: {
      const bets = [...state.bets];
      bets[action.payload.index].oddValue = action.payload.odds
        ? action.payload.odds
        : null;
      console.log(bets);
      return {
        ...state,
        bets,
      };
    }
    case REMOVE_EXCH_BET: {
      const bets = [...state.bets];
      bets.splice(action.payload.index, 1);

      return {
        ...state,
        bets,
      };
    }
    case SET_EXCH_BET_STAKE: {
      const bets = [...state.bets];
      if (action.payload.type === 'ADD') {
        bets[action.payload.index].amount += action.payload.amount;
      } else if (action.payload.type === 'SET') {
        bets[action.payload.index].amount = action.payload.amount;
      }

      if (isNaN(bets[action.payload.index].amount)) {
        bets[action.payload.index].amount = 0;
      }

      return {
        ...state,
        bets,
      };
    }
    case ENABLE_ONE_CLICK_BETS: {
      return {
        ...state,
        isOneClickEnable: action.payload,
      };
    }
    case ADD_ONE_CLICK_AMOUNT: {
      return {
        ...state,
        oneClickAmount: action.payload,
      };
    }

    case CLEAR_EXCHANGE_BETS: {
      const bettingInprogress = state.bettingInprogress;
      if (!bettingInprogress) {
        return {
          ...state,
          bets: [],
        };
      }
      return {
        ...state,
      };
    }
    case SET_BUTTON_VARIABLES: {
      const btnVars = action.payload;
      return {
        ...state,
        buttonVariables: btnVars.length > 0 ? btnVars : BUTTON_VARIABLES,
      };
    }
    case FETCH_OPEN_BETS_SUCCESS: {
      const openBets = action.payload ? action.payload.result : [];
      const totalOrders = action.payload ? action.payload.totalOrders : 0;

      return {
        ...state,
        openBets: openBets,
        totalOrders: totalOrders,
      };
    }
    case SET_BETTING_INPROGRESS: {
      return {
        ...state,
        bettingInprogress: action.payload,
      };
    }
    case CLEAR_SUCCESS_BETS: {
      // const currentBets = [...state.bets];
      // const successIndices: number[] = action.payload;

      // const filteredBets = currentBets.filter(
      //   (_, i) => !successIndices.includes(i)
      // );

      return {
        ...state,
        bets: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default ExchBetslipReducer;
